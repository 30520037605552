<template>
	<div id="code">
		111111
	</div>
</template>

<script>
	export default {
		data() {
			return {
				html: "https://jinshuju.net/f/zMw9Ew",
			}
		},
		created() {
			// this.html = this.$route.query.html;
			// console.log(this.html)
		} 
	}
</script>

<style lang="less">

</style>